import { range } from 'lodash';
import { observable } from 'mobx';

import { PerspectiveSpreadType } from 'app/constants';
import { ModelList } from 'app/models/ModelList';
import TeamPerspectiveResultStore from 'app/stores/TeamPerspectiveResultStore';

import MemberAvatarModel from './MemberAvatarModel';
import Model, { ModelJson } from './Model';
import PerspectiveDimensionModel, { PerspectiveDimensionTip } from './PerspectiveDimensionModel';
import PerspectiveMemberModel from './PerspectiveMemberModel';

export interface LensScoreMember {
  id: number;
  name: string;
  raw_score: string;
  binned_score: number;
  avatar: MemberAvatarModel;
}

export interface LensScoreWithMembers {
  title: string;
  left_label: string;
  right_label: string;
  left_tooltip: string;
  right_tooltip: string;
  members: { [memberId: number]: LensScoreMember };
}

export interface PerspectiveDimensionCompact {
  name: string;
  spreadType?: PerspectiveSpreadType;
  differences?: { brief: string; rest: string }[];
  description: string;
  leftLabel: string;
  rightLabel: string;
  leftToolTip: string;
  rightToolTip: string;
  items: {
    name: string;
    avatar?: MemberAvatarModel;
    score: number;
  }[];
  lensesScores: { [lens: string]: LensScoreWithMembers };
  stacks: PerspectiveDimensionStackItem[][];
  risks?: string;
  tips?: PerspectiveDimensionTip[];
}

export interface PerspectiveDimensionStackItem {
  label: string;
  content: string;
  score: number;
  id: number;
  color: string;
}

export class TeamPerspectiveResultModel extends Model {
  static _store: TeamPerspectiveResultStore;

  @observable id: string;
  @observable dimensions = new ModelList<PerspectiveDimensionModel>(PerspectiveDimensionModel);
  @observable members?: { [id: string]: PerspectiveMemberModel };
  @observable absentees?: { [id: string]: PerspectiveMemberModel };
  @observable lensesScores?: { [dimension: string]: { [lens: string]: LensScoreWithMembers } };
  @observable canManage?: boolean;
  @observable isManagerCoachDisabled?: boolean;

  deserialize_members(members) {
    const mapping = {} as { [id: number]: PerspectiveMemberModel };
    for (const memberId in members) {
      mapping[memberId] = PerspectiveMemberModel.fromJson(members[memberId]);
    }
    this.members = mapping;
  }

  deserialize_absentees(absentees) {
    const mapping = {} as { [id: number]: PerspectiveMemberModel };
    for (const absenteeId in absentees) {
      mapping[absenteeId] = PerspectiveMemberModel.fromJson(absentees[absenteeId]);
    }
    this.absentees = mapping;
  }

  deserialize_dimensions(dimensions) {
    const allDimensions = dimensions.map((dimension) => {
      return PerspectiveDimensionModel.fromJson(dimension);
    });

    this.dimensions.appendItems(allDimensions);
  }

  static getEmptyTeamStackItems() {
    const empty = {
      name: '',
      spreadType: null,
      leftLabel: '',
      rightLabel: '',
      leftToolTip: '',
      rightToolTip: '',
      items: [],
      description: '',
      differences: [],
      diffIntro: '',
      risks: '',
      tips: [],
      stacks: [],
      lenses: [],
      lensesScores: {},
    };

    return {
      energy: empty,
      processing: empty,
      decisions: empty,
      structure: empty,
    };
  }

  getTeamStackItems(): { [dimension: string]: PerspectiveDimensionCompact } {
    const data = TeamPerspectiveResultModel.getEmptyTeamStackItems();

    this.dimensions.items.forEach((dimension) => {
      const item = {
        name: dimension.dimensionDisplayName,
        spreadType: dimension.spreadType,
        differences: dimension.differences,
        description: dimension.descriptionHtml,
        leftLabel: dimension.leftLabel,
        rightLabel: dimension.rightLabel,
        leftToolTip: dimension.leftToolTip,
        rightToolTip: dimension.rightToolTip,
        diffIntro: '',
        items: dimension.memberScores.map(({ memberId, binnedScore }) => ({
          score: binnedScore,
          avatar: this.members[memberId].avatar,
          name: this.members[memberId].name,
        })),
        lensesScores: this.lensesScores?.[dimension.dimensionDisplayName] ?? {},
        stacks: [],
        risks: dimension.risks,
        tips: dimension.tips,
      };

      item.stacks = this.getDimensionStacks(item);

      data[dimension.dimensionDisplayName] = item;
    });

    return data;
  }

  getDimensionStacks(dimension: PerspectiveDimensionCompact): PerspectiveDimensionStackItem[][] {
    const formattedItems = dimension.items.map((item) => ({
      label: item.avatar.text,
      content: item.name,
      score: item.score,
      id: item.avatar.id,
      color: item.avatar.color,
    }));

    const scores = range(-3, 4);
    const initialStacks = Array.from({ length: 7 }).map(() => []);

    return formattedItems.reduce((acc, item) => {
      const index = scores.indexOf(item.score);
      acc[index].push(item);
      return acc;
    }, initialStacks);
  }

  get completedMembers() {
    return Object.values(this.members).filter(({ letters }) => !!letters);
  }

  get nonCompletedMembers() {
    return Object.values(this.members).filter(({ letters }) => !letters);
  }

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as TeamPerspectiveResultModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as TeamPerspectiveResultModel;
  }

  static get(id) {
    return this._get(id) as TeamPerspectiveResultModel;
  }
}
