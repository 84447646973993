// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZFK39pUoLSoInfEPP2MTMg\\=\\=,.ZFK39pUoLSoInfEPP2MTMg\\=\\= h1{margin-top:1rem}.ZFK39pUoLSoInfEPP2MTMg\\=\\= h1{align-items:center;display:flex;margin-bottom:1.875rem}.ZFK39pUoLSoInfEPP2MTMg\\=\\= h1 span{margin-right:1rem}.ZM-ZbFaDI\\+wWvgzi6YsB4A\\=\\={display:flex;justify-content:flex-end}", "",{"version":3,"sources":["webpack://./resources/assets/js/components/ui/PageTitle/PageTitle.module.scss"],"names":[],"mappings":"AAGE,2DAFA,eAOF,CALE,+BAEE,kBAAA,CADA,YAAA,CAEA,sBAEJ,CACI,oCACE,iBACN,CAIA,6BACE,YAAA,CACA,wBADF","sourcesContent":[".header {\n  margin-top: 1rem;\n\n  h1 {\n    display: flex;\n    align-items: center;\n    margin-bottom: 1.875rem;\n    margin-top: 1rem;\n\n    span {\n      margin-right: 1rem;\n    }\n  }\n}\n\n.actionCol {\n  display: flex;\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "ZFK39pUoLSoInfEPP2MTMg==",
	"actionCol": "ZM-ZbFaDI+wWvgzi6YsB4A=="
};
export default ___CSS_LOADER_EXPORT___;
