import React, { ReactElement, ReactNode } from 'react';

import { LinkOutlined } from '@ant-design/icons';
import { Popover, Tooltip } from 'antd';
import cx from 'classnames';

import './ScaleBarStack.scss';
import popoverContentStyles from './ScaleBarStackPopoverStyling.module.scss';

import { ServerRouteHelper } from 'app/helpers';

import FoldingItem from '../FoldingItem';
import ScaleBar from '../ScaleBar';

const FOLDING_BREAK = 8;

interface ScaleBarStack {
  label: string;
  content: string;
  id: number;
  color: string;
  score: number;
}

export interface ScaleBarModalItem {
  stack: ScaleBarStack[];
  title?: string;
  context?: string;
}

interface ScaleBarStackProps {
  className: string;
  leftLabel: string;
  rightLabel: string;
  leftToolTip: string;
  rightToolTip: string;
  stacks: ScaleBarStack[][];
  isTooltipStatic?: boolean;
  canManageTeam?: boolean;
  isManagerCoachDisabled?: boolean;
  showPopover?: boolean;
  context?: string;
  onHandleFolding?: (scaleBarModalItem: ScaleBarModalItem) => void;
}

interface ScaleBarStackItemProps {
  className: string;
  label: string;
  content: string;
  id?: number;
  canManageTeam: boolean;
  showPopover: boolean;
  isManagerCoachDisabled?: boolean;
}

const ScaleBarStackItem: React.FC<ScaleBarStackItemProps> = ({
  className,
  label,
  content,
  canManageTeam,
  showPopover,
  isManagerCoachDisabled,
  id,
}): ReactElement => {
  const teamId = () => {
    return parseInt(window.location.pathname.replace('/perspective/report/team/', ''));
  };

  const handleViewGuide = () => {
    window.open(ServerRouteHelper.dashboard.managerPlaybook(teamId(), id));
  };

  const handleViewPairPerspective = () => {
    window.open(ServerRouteHelper.perspective.report.pair(teamId(), id));
  };

  const popoverContentTitle = (
    <div className={popoverContentStyles.popoverContentTitle}>
      <div className={cx('scale-bar-stack-item', className)}>
        <span>{label}</span>
      </div>
      <div className={popoverContentStyles.label}>{content}</div>
    </div>
  );

  const coachPopoverContent = (
    <div className={popoverContentStyles.popoverContent} onClick={handleViewGuide}>
      <LinkOutlined /> View Manager Guide
    </div>
  );

  const pairPopoverContent = (
    <div className={popoverContentStyles.popoverContent} onClick={handleViewPairPerspective}>
      <LinkOutlined /> View Pair Perspective
    </div>
  );

  const popover = () => (
    <Popover
      title={popoverContentTitle}
      content={canManageTeam && !isManagerCoachDisabled ? coachPopoverContent : pairPopoverContent}
      placement="topLeft"
    >
      <span translate="no">{label}</span>
    </Popover>
  );

  const tooltip = () => (
    <Tooltip title={content}>
      <span translate="no">{label}</span>
    </Tooltip>
  );

  return (
    <div className={cx('scale-bar-stack-item', className)}>
      {showPopover ? popover() : tooltip()}
    </div>
  );
};

const ScaleBarStack: React.FC<ScaleBarStackProps> = ({ stacks, ...rest }): ReactElement => {
  const handleFolding = (stack: ScaleBarStack[], context) => {
    rest.onHandleFolding({ stack, context });
  };

  const renderStacks = (stack: ScaleBarStack[]): ReactNode => {
    const slicedStack = stack.slice(0, FOLDING_BREAK);
    const foldingRequired = stack.length > FOLDING_BREAK;

    const renderedStacks = slicedStack.map((item, j) => {
      return (
        <ScaleBarStackItem
          key={j}
          className={rest.className}
          {...item}
          canManageTeam={rest.canManageTeam}
          isManagerCoachDisabled={rest.isManagerCoachDisabled}
          showPopover={rest.showPopover}
        />
      );
    });

    return (
      <>
        {foldingRequired && (
          <FoldingItem
            foldedCount={stack.length - FOLDING_BREAK}
            onHandleFolding={handleFolding}
            stack={stack}
            context={rest.context}
          />
        )}
        {renderedStacks}
      </>
    );
  };

  const wrappedStacks = stacks.map((stack, i) => (
    <div key={i} className="scale-bar-stack">
      {renderStacks(stack)}
    </div>
  ));

  return <ScaleBar {...rest} stacks={wrappedStacks} />;
};

export default ScaleBarStack;
