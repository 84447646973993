import { notification } from 'antd';
import { map } from 'lodash';
import { action, computed, observable } from 'mobx';

import { ClientDataHelper, ServerRouteHelper } from 'app/helpers';
import { MemberItem, PagingInfoModel, PagingMetaModel, PulseTemplateModel } from 'app/models';
import ExerciseTypeModel from 'app/models/ExerciseTypeModel';
import MemberModel from 'app/models/MemberModel';
import { ModelItem } from 'app/models/ModelItem';
import { ModelList } from 'app/models/ModelList';
import type { TeamProps } from 'app/models/TeamModel';
import TeamModel from 'app/models/TeamModel';
import Store from 'app/stores/Store';

const DEFAULT_TEAMS_PAGE_NUMBER = 1;
const DEFAULT_TEAMS_PAGE_SIZE = 10;
const DEFAULT_SLIM_TEAMS_PAGE_SIZE = 20;

export interface SendEmailToMemberOptions {
  invite: boolean;
  type?: string;
}

export enum TeamStoreIncludes {
  MEMBERS = 'members',
  MANAGER = 'manager',
  SECONDARY_MANAGER = 'secondary_manager',
  ORGANIZATION = 'organization',
  TEAM_GAMES = 'team_games',
  PULSE_TEMPLATES = 'pulse_templates',
  PULSE_TEMPLATES_DISABLED = 'pulse_templates_disabled',
  TEAM_GROUPS = 'team_groups',
  EXERCISE_TYPES = 'exercise_types',
  ORGANIZATION_TEAMS = 'organization.teams',
  ORGANIZATION_TEAM_GROUPS = 'organization.team_groups',
  ORGANIZATION_TEAM_GROUP_DIVISIONS = 'organization.team_group_divisions',
  ORGANIZATION_EXERCISE_TYPES = 'organization.exercise_types',
}

export class TeamStore extends Store<TeamModel> {
  @observable team = new ModelItem<TeamModel>(TeamModel);
  @observable teams = new ModelList<TeamModel>(TeamModel);
  @observable memberTeams = new ModelList<TeamModel>(TeamModel);
  @observable memberManagedTeams = new ModelList<TeamModel>(TeamModel);
  @observable slimMemberTeams = new ModelList<TeamModel>(TeamModel);
  @observable slimMemberPartialTeams = new ModelList<TeamModel>(TeamModel);
  @observable otherTeams = new ModelList<TeamModel>(TeamModel);
  @observable memberPartialTeams = new ModelList<TeamModel>(TeamModel);
  @observable allTeams = new ModelList<TeamModel>(TeamModel);
  @observable managedDirectlyOrSecondaryTeams = new ModelList<TeamModel>(TeamModel);
  @observable memberPartialTeamsPagingInfo = new PagingInfoModel({
    page: DEFAULT_TEAMS_PAGE_NUMBER,
    page_size: DEFAULT_TEAMS_PAGE_SIZE,
  });
  @observable public membersPartialTeamsPageMeta: PagingMetaModel;

  @observable public memberManagedAndSecondaryTeamsPagingInfo: PagingMetaModel;

  @observable memberPartialSlimTeamsPagingInfo = new PagingInfoModel({
    page: DEFAULT_TEAMS_PAGE_NUMBER,
    page_size: DEFAULT_SLIM_TEAMS_PAGE_SIZE,
  });
  @observable public membersPartialSlimTeamsPageMeta: PagingMetaModel;
  @observable public overviewTeams = new ModelList<TeamModel>(TeamModel);

  @observable memberTeamsLoaded = false;
  @action setMemberTeamsLoaded = (loaded: boolean): void => {
    this.memberTeamsLoaded = loaded;
  };

  @observable overviewTeamsLoaded = false;
  @action setOverviewTeamsLoaded = (loaded: boolean): void => {
    this.overviewTeamsLoaded = loaded;
  };

  @observable previousOverViewTeamIdAndOrgId: { teamId: number; orgId: number } = null;

  @action setPreviousOverViewTeamIdAndOrgId = (teamId: number, orgId: number): void => {
    this.previousOverViewTeamIdAndOrgId = { teamId, orgId };
  };

  @observable currentManagers: MemberItem[] = [];

  constructor() {
    super();
    TeamModel._store = this;
  }

  @observable teamsManaged: TeamModel[];
  @action setTeamsManaged = (teams: TeamModel[]): void => {
    this.teamsManaged = teams;
  };

  @observable teamsMemberOf: TeamModel[];
  @action setTeamsMemberOf = (teams: TeamModel[]): void => {
    this.teamsMemberOf = teams;
  };

  @observable isCreatingTeam = false;
  @action setIsCreatingTeam = (isCreatingTeam: boolean): void => {
    this.isCreatingTeam = isCreatingTeam;
  };

  @observable isUpdatingTeam = false;
  @action setIsUpdatingTeam = (isUpdatingTeam: boolean): void => {
    this.isUpdatingTeam = isUpdatingTeam;
  };

  loadTeamForGuest(teamId: number, includes: string[] = []) {
    if (!teamId) {
      return;
    }

    const params = {
      includes: includes.join(','),
      token: this.sharedLinkToken,
    };

    const url = ServerRouteHelper.api.teams.showForGuest(teamId);

    return this.team.load(url, params, { headers: this.defaultHeaders });
  }

  async loadTeams(fields = ['id', 'name'], requestRequiredTeam?: number) {
    const teams = await ClientDataHelper.get('teams');
    if (teams) {
      this.teams.setLoading(true);
      this.teams.setLoaded(false);

      this.teams.appendItems(teams.map((team) => TeamModel.fromJson(team)));

      this.teams.setLoading(false);
      this.teams.setLoaded(true);
      return;
    }

    const url = ServerRouteHelper.api.teams.list(this.sharedLinkToken);
    const query = { fields: fields.join(',') };
    const config = { dataKey: null };

    if (requestRequiredTeam) {
      query['required_team'] = requestRequiredTeam;
    }

    await this.teams.load(url, query, config);
  }

  async loadMemberTeams(orgId?: number): Promise<void> {
    const url = ServerRouteHelper.api.teams.memberTeams(orgId);
    const config = { url };

    this.setMemberTeamsLoaded(false);

    const response = await this.apiService.newGet(config);

    if (response) {
      this.setTeamsManaged(response.managed_teams);
      this.setTeamsMemberOf(response.member_teams);

      response.member_teams.forEach((team) => {
        if (!this.currentManagers.find((manager) => manager.id === team.manager.id)) {
          this.currentManagers.push(team.manager);
        }
      });
    }

    this.setMemberTeamsLoaded(true);
  }

  @action
  loadTeam(
    teamId: number,
    includes: string[] = [],
    redirectIfUnauthorized = true,
    forceRefresh = false
  ): Promise<any> {
    const url = ServerRouteHelper.api.teams.show(teamId);
    const params = {
      includes: includes.join(','),
    };
    return this.team?.load(url, params, { redirectIfUnauthorized, forceRefresh });
  }

  @action
  async loadTeamById(teamId: number, includes: string[] = []): Promise<void> {
    const url = ServerRouteHelper.api.teams.show(teamId);

    const params = {
      includes: includes.join(','),
    };

    const config = { url, params };
    const response = await this.apiService.newGet(config);

    if (response?.data) {
      const teamItem = TeamModel.fromJson(response.data);
      this.teams.appendItem(teamItem);
    }
  }

  // Load a single team but append to teams field,
  // this is mainly for test-drive so we can load just
  // that single team and the component can still behave
  // by using teamStore.teams as they normally would.
  loadTestDriveTeam = async (teamId: number): Promise<void> => {
    await this.loadTeamForGuest(teamId);

    // Append test-drive team to teams and turn-on its loaded flag
    this.teams.appendItem(this.team.item);
    this.teams.loaded = true;
  };

  @action
  async importMembersOfOtherTeam(destinationTeamId, sourceTeamId, includes: string[] = []) {
    const team = TeamModel.getOrNew(destinationTeamId);
    if (!team) {
      return;
    }

    const url = ServerRouteHelper.api.teams.members.import(destinationTeamId);
    const config = {
      url,
      data: { team_id: sourceTeamId },
      params: {
        includes: includes.join(','),
      },
      throwError: true,
    };

    const response = await this.apiService.newPost(config);

    team.members.appendItems(response.data.map((member) => MemberModel.fromJson(member)));
    return response;
  }

  @action
  listTeamMembers(teamId: number, includes: string[] = []) {
    const team = TeamModel.getOrNew(teamId);
    if (!team) {
      return;
    }

    return team.members.load(ServerRouteHelper.api.teams.members.list(team.id), {
      includes: includes.join(','),
    });
  }

  async addMembers(
    team: TeamModel,
    members,
    includes: string[] = [],
    source: string = null
  ): Promise<any> {
    const currentTeamMemberIds = map(team.members.items, 'id');
    const config = {
      url: ServerRouteHelper.api.teams.members.add(team.id),
      data: { members, source, token: this.sharedLinkToken },
      headers: null,
      params: { includes: includes.join(',') },
      showGenericError: true,
    };

    const response = await this.apiService.newPost(config);

    if (!response?.data) {
      return;
    }

    const teamMembers = response.data;
    const newlyAddedMembers = teamMembers.filter(
      (member) => !currentTeamMemberIds.includes(member.id)
    );

    team.members.appendItems(response.data.map((member) => MemberModel.fromJson(member)));
    team.newMembers.appendItems(newlyAddedMembers.map((member) => MemberModel.fromJson(member)));

    // Update the team member count
    team.membersCount += newlyAddedMembers.length;

    // Update team member count in managed teams or in team member of (team where current member is a member)
    this.updateTeamMembersCount(team.id, newlyAddedMembers.length);

    return response.data;
  }

  @action
  async removeMember(team: TeamModel, member_id: number, source: string = null) {
    const url = ServerRouteHelper.api.teams.members.remove(team.id, member_id);

    const config = {
      url,
      params: { source },
      throwError: true,
      showGenericError: false,
    };

    await this.apiService.newDelete(config);
    team.members.setItems(team.members.items.filter((item) => item.id !== member_id));

    // If the removed member is a secondary manager
    team.secondary_manager?.item?.id === member_id && team.secondary_manager.setItem(null);

    // Update the team member count
    team.membersCount -= 1;

    // Update team member count in managed teams or in team member of (team where current member is a member)
    this.updateTeamMembersCount(team.id, -1);
  }

  @action
  async assignTeamManager(team: TeamModel, member: MemberModel, isPrimary: boolean): Promise<void> {
    const url = ServerRouteHelper.api.teams.assignTeamManager(team.id, member.id);

    const config = {
      url,
      throwError: true,
      showGenericError: false,
      data: { role: isPrimary ? 'primary' : 'secondary' },
    };

    const response = await this.apiService.newPost(config);
    if (!response) {
      return;
    }

    if (isPrimary) {
      team.manager.setItem(response.data);
    }

    if (!isPrimary) {
      team.secondary_manager.setItem(response.data);
    }
  }

  @action
  async revokeSecondaryTeamManager(team: TeamModel, member: MemberModel): Promise<void> {
    const url = ServerRouteHelper.api.teams.revokeSecondaryTeamManager(team.id, member.id);

    const config = {
      url,
      throwError: true,
      showGenericError: false,
    };

    const response = await this.apiService.newDelete(config);

    if (!response) {
      return;
    }

    team.updateFromJson(response.data);
  }

  async createTeam(teamProps: TeamProps, creation_type = ''): Promise<any> {
    const { team } = await this.createTeamWithMeta(teamProps, creation_type);
    return team;
  }

  @action
  async createTeamWithMeta(
    teamProps: TeamProps,
    creation_type = '',
    productName = ''
  ): Promise<any> {
    const data = {
      name: teamProps.name,
      organization_id: teamProps.organization_id,
    } as any;

    if (teamProps.secondary_manager?.name && teamProps.secondary_manager?.email) {
      const { name, email } = teamProps.secondary_manager;
      data['secondary-manager'] = { name, email };
    }

    if (teamProps.group_map) {
      const group_ids = Object.values(teamProps.group_map);
      if (group_ids.length > 0) {
        data.group_ids = group_ids;
      }
    }

    if (creation_type) {
      data['creation_type'] = creation_type;
    }

    try {
      const url = ServerRouteHelper.api.teams.create();
      const config = {
        url,
        data: { team: data, productName, token: this.sharedLinkToken },
        showGenericError: true,
      };

      this.setIsCreatingTeam(true);

      const response = await this.apiService.newPost(config);

      if (!response) {
        return { team: null, redirectTo: null };
      }

      const team = TeamModel.fromJson(response.team);
      this.team.setItem(team);

      return { team, redirectTo: response.redirect_to };
    } finally {
      this.setIsCreatingTeam(false);
    }
  }

  @action
  async updateTeam(teamId: number, data: Partial<TeamModel>): Promise<any> {
    try {
      const team = TeamModel.getOrNew(teamId);
      if (!team) {
        return;
      }

      this.setIsUpdatingTeam(true);

      const config = {
        url: ServerRouteHelper.api.teams.update(teamId),
        data,
        showGenericError: true,
      };

      const response = await this.apiService.newPatch(config);

      if (response?.data) {
        return TeamModel.fromJson(response.data);
      }
    } finally {
      this.setIsUpdatingTeam(false);
    }
  }

  @action
  async adminUpdateTeam(teamId: number, data: Partial<TeamModel>) {
    const team = TeamModel.getOrNew(teamId);
    if (!team) {
      return;
    }

    const url = ServerRouteHelper.api.admin.teams.team(teamId);
    const config = {
      url,
      data: data,
      showGenericError: true,
    };
    const response = await this.apiService.newPatch(config);

    if (!response) {
      return;
    }

    return team.updateFromJson(response.data);
  }

  @action
  async deleteTeam(team: TeamModel) {
    await this.apiService.delete(ServerRouteHelper.api.teams.show(team.id));
    this.teams.deleteItem(team);
    this.memberTeams.deleteItem(team);
    this.overviewTeams.deleteItem(team);
  }

  @action
  async listOtherTeams(teamId: number) {
    await this.otherTeams.load(ServerRouteHelper.api.teams.otherTeams(teamId));
  }

  async mergeToTeam(teamId: number, otherTeamId: number) {
    return this.apiService.get(ServerRouteHelper.api.admin.teams.mergeToTeam(teamId, otherTeamId));
  }

  async promoteToMembers(teamId: number) {
    return this.apiService.get(ServerRouteHelper.api.admin.teams.promoteToMembers(teamId));
  }

  async saveExerciseTypes(teamId: number, exerciseTypes: ExerciseTypeModel[]) {
    const exercise_type_ids = exerciseTypes.map((exerciseType) => exerciseType.id);
    const url = ServerRouteHelper.api.admin.teams.exerciseTypes(teamId);

    const config = {
      url,
      data: { exercise_type_ids },
      throwError: true,
    };

    await this.apiService.newPost(config);
  }

  @action
  async savePulseTemplates(teamId: number, templates: PulseTemplateModel[]): Promise<void> {
    const templateIds = templates.map((template) => template.id);
    const url = ServerRouteHelper.api.admin.pulses.templates.saveTeamTemplates(teamId);

    try {
      const config = {
        url,
        data: { templateIds },
        throwError: true,
      };

      await this.apiService.newPost(config);

      notification.success({
        message: 'Pulse templates',
        description: 'Successfully saved pulse templates',
        placement: 'bottomRight',
      });
    } catch (err) {
      notification.error({
        message: 'Pulse templates',
        description: 'Unable to save pulse templates, we are looking into it',
        placement: 'bottomRight',
      });
    }
  }

  @action
  async loadAllTeamsByOrgId(orgId: number): Promise<void> {
    const url = ServerRouteHelper.api.organizations.allTeams(orgId);
    await this.allTeams.load(url);
  }

  async getCurrentMemberTeams(organizationId?: number, params = {}): Promise<void> {
    const _params = {
      organization_id: organizationId,
      ...params,
    };

    await this.memberTeams.load(ServerRouteHelper.api.members.currentMemberTeams(), _params);
  }

  async getCurrentMemberManagedTeams(orgId: number): Promise<void> {
    const url = ServerRouteHelper.api.members.currentMemberManagedTeams(orgId);
    await this.memberManagedTeams.load(url);
  }

  async getCurrentMemberManagedOrSecondaryTeams(orgId: number, page?: number): Promise<void> {
    const params = { page };

    const url = ServerRouteHelper.api.members.currentMemberManagedOrSecondaryTeams(orgId);
    const response = await this.managedDirectlyOrSecondaryTeams.load(url, params);
    this.memberManagedAndSecondaryTeamsPagingInfo = new PagingMetaModel(response.meta);
  }

  async getCurrentMemberPaginatedSlimTeams(): Promise<void> {
    if (
      this.membersPartialSlimTeamsPageMeta &&
      this.membersPartialSlimTeamsPageMeta?.current_page ===
        this.membersPartialSlimTeamsPageMeta?.last_page
    ) {
      return;
    }

    const params = {
      minimal: true,
      page: this.memberPartialSlimTeamsPagingInfo.page,
      page_size: this.memberPartialSlimTeamsPagingInfo.page_size,
    };

    const url = ServerRouteHelper.api.members.currentMemberTeams();
    const response = await this.slimMemberPartialTeams.load(url, params);

    if (!response) {
      return;
    }

    this.slimMemberTeams.appendItems(this.slimMemberPartialTeams.items);
    this.membersPartialSlimTeamsPageMeta = new PagingMetaModel(response.meta);
    this.memberPartialSlimTeamsPagingInfo.incrementPage();
  }

  async getCurrentMemberPaginatedTeams(
    pageSize?: number,
    organizationId?: number,
    teamId?: number,
    onlyManaged?: boolean
  ): Promise<void> {
    // If last page is loaded
    if (
      !!this.membersPartialTeamsPageMeta?.current_page &&
      this.membersPartialTeamsPageMeta.current_page >= this.membersPartialTeamsPageMeta.last_page
    ) {
      return;
    }

    this.setOverviewTeamsLoaded(false);

    if (this.memberPartialTeamsPagingInfo.page == 1) {
      this.memberPartialTeams.setItems([]);
    }

    const params = {
      page: this.memberPartialTeamsPagingInfo.page,
      page_size: pageSize || this.memberPartialTeamsPagingInfo.page_size,
      organization_id: organizationId,
      sorted_team_id: teamId,
      managed: onlyManaged,
    };

    const url = ServerRouteHelper.api.members.currentMemberTeamsOverview();
    const response = await this.memberPartialTeams.load(url, params, { forceRefresh: true });

    if (!response) {
      return;
    }

    if (this.memberPartialTeamsPagingInfo.page == 1) {
      this.overviewTeams.setItems([]);
    }

    // Continuously append to the overviewTeams list
    this.memberPartialTeams.items.forEach((team) => {
      if (this.overviewTeams.hasItem(team.id)) {
        this.overviewTeams.deleteItem(team);
      }
    });

    this.overviewTeams.appendItems(this.memberPartialTeams.items);
    this.membersPartialTeamsPageMeta = new PagingMetaModel(response.meta);
    this.memberPartialTeamsPagingInfo.incrementPage();

    this.setOverviewTeamsLoaded(true);

    this.setPreviousOverViewTeamIdAndOrgId(teamId, organizationId);
  }

  resetTeamsOverviewPagination = (): void => {
    this.memberPartialTeamsPagingInfo.resetPage();

    if (this.membersPartialTeamsPageMeta) {
      this.membersPartialTeamsPageMeta.current_page = null;
    }
  };

  @computed
  get shouldLoadMoreTeams(): boolean {
    return (
      this.memberPartialTeams.loading ||
      this.membersPartialTeamsPageMeta?.current_page < this.membersPartialTeamsPageMeta?.last_page
    );
  }

  @action
  private updateTeamMembersCount = (teamId: number, count: number): void => {
    const managedTeam = this.teamsManaged?.find((team) => team.id === teamId);
    if (managedTeam) {
      managedTeam.membersCount += count;
    }

    const memberTeam = this.teamsMemberOf?.find((team) => team.id === teamId);
    if (memberTeam) {
      memberTeam.membersCount += count;
    }
  };
}

export default TeamStore;
