import React, { ReactElement } from 'react';

import cx from 'classnames';

import './ScaleBar.scss';

import mbtiReportMapping from 'app/../perspective/mbti-report-strings.json';
import PerspectiveReportTraitCardLabel from 'app/components/features/PerspectiveTraitCard/PerspectiveReportTraitCardLabel';

interface ScaleBarProps {
  className: string;
  leftLabel: string;
  rightLabel: string;
  leftToolTip: string;
  rightToolTip: string;
  stacks?: ReactElement[];
  isTooltipStatic?: boolean;
}

const ScaleBar: React.FC<ScaleBarProps> = ({
  className,
  leftLabel,
  rightLabel,
  leftToolTip,
  rightToolTip,
  stacks,
  isTooltipStatic,
}): ReactElement => {
  const labels = mbtiReportMapping.bar.axisLabels;

  return (
    <div className="scale-bar">
      <div className="scale-bar-levels">
        {labels.map((label, i) => (
          <div key={i} className={cx('scale-bar-level', `scale-bar-level-${i}`)}>
            {stacks?.[i]}
            <div className={cx('scale-bar-level-base', className)}>
              <span className="scale-bar-level-text">{label}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="scale-bar-labels">
        <PerspectiveReportTraitCardLabel
          className="perspective-report-trait-card-label-left"
          label={leftLabel}
          tooltip={leftToolTip}
          tooltipPosition="left"
          isTooltipStatic={isTooltipStatic}
        />
        <PerspectiveReportTraitCardLabel
          className="perspective-report-trait-card-label-right"
          label={rightLabel}
          tooltip={rightToolTip}
          tooltipPosition="right"
          isTooltipStatic={isTooltipStatic}
        />
      </div>
    </div>
  );
};

export default ScaleBar;
