import { useEffect } from 'react';

/**
 * HTML body class helpers
 */
export const addBodyClass = (className) => document.body.classList.add(className);
export const removeBodyClass = (className) => document.body.classList.remove(className);

/**
 * Set a css class on the HTML Body of a document.
 */
export const useBodyClass = (...classes: string[]): void => {
  useEffect(() => {
    // Set state
    classes.forEach(addBodyClass);

    // Clean up state
    return () => classes.forEach(removeBodyClass);
  }, [classes]);
};
