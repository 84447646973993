import React from 'react';

import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import cx from 'classnames';

import './PerspectiveReportTraitCardLabel.scss';

export interface PerspectiveReportTraitCardLabelProps {
  label: string;
  className?: string;
  tooltip?: string;
  tooltipPosition?: TooltipPlacement;
  isTooltipStatic?: boolean;
}

export const PerspectiveReportTraitCardLabel: React.FC<PerspectiveReportTraitCardLabelProps> = ({
  label,
  className,
  tooltip,
  tooltipPosition,
  isTooltipStatic,
}) => {
  const tip = (
    <Tooltip placement={tooltipPosition} title={tooltip}>
      <i className="fal fa-question-circle" />
    </Tooltip>
  );

  const tipStaticLayout = (
    <>
      <span>{label}</span>
      <span className="static-tooltip">{tooltip}</span>
    </>
  );

  const nonTipStaticLayout = (
    <>
      {tooltip && tooltipPosition === 'left' && tip}
      <span>{label}</span>
      {tooltip && tooltipPosition === 'right' && tip}
    </>
  );

  return (
    <div className={cx('perspective-report-trait-card-label', className)}>
      {isTooltipStatic && tipStaticLayout}
      {!isTooltipStatic && nonTipStaticLayout}
    </div>
  );
};

export default PerspectiveReportTraitCardLabel;
