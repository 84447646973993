import React, { ReactElement } from 'react';

import styles from './FoldingItem.modules.scss';

import ScaleBarStack from '../ScaleBarStack';

interface FoldingItemProps {
  foldedCount: number;
  stack: ScaleBarStack[];
  context?: string;
  onHandleFolding: (slack: ScaleBarStack[], context: string) => void;
}

const FoldingItem: React.FC<FoldingItemProps> = ({
  foldedCount,
  stack,
  context,
  onHandleFolding,
}): ReactElement => {
  const handleFolding = () => {
    onHandleFolding(stack, context);
  };

  return (
    <button className={styles.foldingItem} onClick={handleFolding} data-testid="folding-item">
      <span>+ {foldedCount}</span>
    </button>
  );
};

export default FoldingItem;
