import React, { MouseEventHandler, ReactNode } from 'react';

import { Modal } from 'antd';
import cx from 'classnames';

import styles from './AntModal.module.scss';

import Footer from './Footer';
import Header from './Header';

const WIDTH = 800;

export interface ModalAction {
  label: string;
  onClick: MouseEventHandler;
  [key: string]: any;
  id?: string;
  className?: string;
  loading?: boolean;
  footerClassName?: string;
  disabled?: boolean;
}

export interface AntModalProps {
  isOpen: boolean;
  loading?: boolean;
  largeTitled?: boolean;
  balancedHeader?: boolean;
  title?: ReactNode;
  dataTrackPrefix?: string;
  closeButtonTag?: string;
  footer?: ReactNode;
  footerClassName?: string;
  primaryAction?: ModalAction;
  secondaryAction?: ModalAction;
  className?: string;
  width?: number;
  onBack?: () => void;
  onToggle?: () => void;
}

const AntModal: React.FC<AntModalProps> = ({
  isOpen,
  loading,
  largeTitled,
  balancedHeader,
  title,
  dataTrackPrefix,
  closeButtonTag,
  footer: footerContent,
  footerClassName,
  primaryAction,
  secondaryAction,
  className,
  children,
  width,
  onBack,
  onToggle,
}) => {
  const header = (
    <Header
      largeTitled={largeTitled}
      balancedHeader={balancedHeader}
      title={title}
      loading={loading}
      dataTrackPrefix={dataTrackPrefix}
      closeButtonTag={closeButtonTag}
      onBack={onBack}
      onToggle={onToggle}
    />
  );

  const footer =
    footerContent || secondaryAction || primaryAction ? (
      <Footer
        content={footerContent}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
        className={footerClassName}
      />
    ) : null;

  return (
    <Modal
      width={width || WIDTH}
      closable={false}
      className={cx(styles.antModal, className, {
        [styles.largeTitled]: largeTitled,
        [styles.balancedHeader]: balancedHeader,
      })}
      open={isOpen}
      title={header}
      footer={footer}
      onOk={onToggle}
      onCancel={onToggle}
    >
      {children}
    </Modal>
  );
};

export default AntModal;
