import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import PerspectiveTeamDiscussionStore from 'app/stores/PerspectiveTeamDiscussionStore';

interface ExtremeLens {
  id: string;
  dimension: string;
  lens: string;
  score: number;
  label: string;
  description: string;
}

export class PerspectiveTeamDiscussionPairModel extends Model {
  static _store: PerspectiveTeamDiscussionStore;

  readonly id: number;
  @observable name: string;
  @observable extreme_lenses: ExtremeLens[];
  @observable pair_url: string;

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as PerspectiveTeamDiscussionPairModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as PerspectiveTeamDiscussionPairModel;
  }

  static get(id) {
    return this._get(id) as PerspectiveTeamDiscussionPairModel;
  }
}

export default PerspectiveTeamDiscussionPairModel;
