export const GREEN = '#20CBAD';
export const GREEN_600 = '#2ab756';
export const GREEN_600_OPACTIY_30 = GREEN_600 + '55';
export const YELLOW_GREEN = GREEN_600;
export const GREEN_LIGHT = '#8DE1A9';
export const GREEN_DARK = '#00A079';
export const BLUE = '#3187E7';
export const ORANGE = '#fa8c16';
export const YELLOW = '#F3B95C';
export const YELLOW_LIGHT = '#FFC35C';
export const RED = '#FA4344';
export const RED_600 = '#cf1322';
export const RED_DARK = RED_600;
export const GRAY_600 = '#6C757D';
export const GRAY_450 = '#E5E5E5';
export const GRAY_300 = '#dee2e6';
export const GRAY_100 = '#fafafa';
export const GRAY_200 = '#f5f5f5';
export const GRAY_400 = '#E8E8E8';
export const GRAY_900 = '#262626';
export const BLACK = '#000000';
export const WHITE = '#ffffff';
export const PINK = '#EB2F96';

export const PRIMARY = '#3D59FA';
export const BLUE_GRAY = '#ADB5BD';
export const BLUE_GRAY_LIGHT = '#D2E1EE';

export const NAV_BLUE = '#113264';
export const BLUE_BRIGHT = '#6682FF';
export const GREEN_LIGHTENED_50 = '#95DE64';
export const RED_LIGHTENED_50 = '#FFA39E';

export const TEAL = '#75DECC';
export const TEAL_DARK = '#13C2C2';
export const CYAN = '#38b8d2';
export const CYAN_300 = '#d7f1f6';
export const INDIGO = '#A920CB';
export const AMBER = '#faad14';
export const AMBER_OPACITY_30 = AMBER + '55';
export const CYAN_600 = '#0c85ad';

export const PURPLE_50 = '#fbf4fc';
export const PURPLE_100 = '#f1eafa';

export const SCATTER_PLOT_AXIS = '#BFBFBF';

// Survey Colors : -3, -2, -1, 0, 1, 2, 3
export const SURVEY_DOT_COLOR_RED = '#CC4D3A'; // -3
export const SURVEY_DOT_COLOR_ORANGE_DARK = '#E9935E'; // -2
export const SURVEY_DOT_COLOR_ORANGE_LIGHT = '#F7D48C'; // -1
export const SURVEY_DOT_COLOR_GRAY = '#D2D3D3'; // 0
export const SURVEY_DOT_COLOR_GREEN_LIGHT = '#D0E890'; // 1
export const SURVEY_DOT_COLOR_GREEN_DARK = '#94C972'; // 2
export const SURVEY_DOT_COLOR_GREEN = '#539F5C'; // 3
export const SURVEY_DOT_COLOR_BLACK = '#000000';
