import { action, observable } from 'mobx';

import { Breakpoints } from 'app/constants';

export class BreakpointStore {
  @observable isMobileDevice = window.matchMedia(Breakpoints.mobile).matches;
  @action setIsMobileDevice = (isMobileDevice: boolean): void => {
    this.isMobileDevice = isMobileDevice;
  };

  @observable isTabletDevice = window.matchMedia(Breakpoints.tablet).matches;
  @action setIsTabletDevice = (isTabletDevice: boolean): void => {
    this.isTabletDevice = isTabletDevice;
  };

  @observable isSmallScreen = window.matchMedia(Breakpoints.smallScreen).matches;
  @action setIsSmallScreen = (isSmallScreen: boolean): void => {
    this.isSmallScreen = isSmallScreen;
  };

  @observable isSmallScreenExact = window.matchMedia(Breakpoints.smallScreenExact).matches;
  @action setIsSmallScreenExact = (isSmallScreenExact: boolean): void => {
    this.isSmallScreenExact = isSmallScreenExact;
  };

  @observable isLargeScreen = window.matchMedia(Breakpoints.largeScreen).matches;
  @action setIsLargeScreen = (isLargeScreen: boolean): void => {
    this.isLargeScreen = isLargeScreen;
  };

  @observable isMediumScreen = window.matchMedia(Breakpoints.mediumScreen).matches;
  @action setIsMediumScreen = (isMediumScreen: boolean): void => {
    this.isMediumScreen = isMediumScreen;
  };

  @observable isMediumOrSmallerScreen = window.matchMedia(Breakpoints.mediumOrSmallerScreen)
    .matches;
  @action setIsMediumOrSmallerScreen = (isMediumOrSmallerScreen: boolean): void => {
    this.isMediumOrSmallerScreen = isMediumOrSmallerScreen;
  };

  constructor() {
    window.addEventListener('resize', this.checkDeviceSize);
    this.checkDeviceSize();
  }

  checkDeviceSize = (): void => {
    const isMobileDevice = window.matchMedia(Breakpoints.mobile).matches;
    this.setIsMobileDevice(isMobileDevice);

    const isTabletDevice = window.matchMedia(Breakpoints.tablet).matches;
    this.setIsTabletDevice(isTabletDevice);

    const isMediumScreen = window.matchMedia(Breakpoints.mediumScreen).matches;
    this.setIsMediumScreen(isMediumScreen);

    const isMediumOrSmallerScreen = window.matchMedia(Breakpoints.mediumOrSmallerScreen).matches;
    this.setIsMediumOrSmallerScreen(isMediumOrSmallerScreen);

    const isSmallScreen = window.matchMedia(Breakpoints.smallScreen).matches;
    this.setIsSmallScreen(isSmallScreen);

    const isSmallScreenExact = window.matchMedia(Breakpoints.smallScreenExact).matches;
    this.setIsSmallScreenExact(isSmallScreenExact);

    const isLargeScreen = window.matchMedia(Breakpoints.largeScreen).matches;
    this.setIsLargeScreen(isLargeScreen);
  };
}

export default BreakpointStore;
