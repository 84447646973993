import { observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { PagingMetaModel, PerspectiveTeamDiscussionPairModel } from 'app/models';
import { LOAD_METHOD } from 'app/models/ModelContainer';
import { ModelList } from 'app/models/ModelList';
import Store from 'app/stores/Store';

export class PerspectiveTeamDiscussionStore extends Store<PerspectiveTeamDiscussionPairModel> {
  @observable discussionPairs = new ModelList<PerspectiveTeamDiscussionPairModel>(
    PerspectiveTeamDiscussionPairModel
  );
  @observable pageMeta: PagingMetaModel;

  constructor() {
    super();
    PerspectiveTeamDiscussionPairModel._store = this;
  }

  async getDiscussionPairs(teamId: number, memberIds: number[] = [], page?: number): Promise<void> {
    const url = ServerRouteHelper.api.perspectives.teamDiscussionPairs(teamId);

    this.discussionPairs.load(
      url,
      { member_ids: memberIds, page },
      {
        method: LOAD_METHOD.POST,
        forceRefresh: true,
        onResponse: (response) => {
          this.pageMeta = new PagingMetaModel(response.meta);
        },
      }
    );
  }
}

export default PerspectiveTeamDiscussionStore;
