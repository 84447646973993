import React, { ReactElement, ReactNode } from 'react';

import { DownOutlined, MoreOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';
import { ButtonSize, ButtonType } from 'antd/lib/button';
import cx from 'classnames';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';

import styles from './CardHeader.module.scss';

import { STORE_BREAKPOINT } from 'app/constants';
import { BreakpointStore } from 'app/stores';
interface CardHeaderProps {
  id?: string;
  heading: string;
  headingRight?: ReactNode;
  subHeading?: ReactNode;
  subHeadingNote?: string;
  image?: ReactNode;
  icon?: ReactNode;
  tag?: ReactNode;
  button?: {
    label: string;
    type?: ButtonType;
    size?: ButtonSize;
    className?: string;
    onClick?: () => void;
    ghost?: boolean;
    id?: string;
  };
  menu?: MenuProps['items'];
  selectMenu?: ReactNode;
  dropdownMenu?: ReactElement;
  backgroundColor?: string;
  className?: string;
  iconClassName?: string;
  tagClassName?: string;
  compact?: boolean;
  collapsible?: boolean;
  isCollapsed?: boolean;
  standalone?: boolean;
  onCollapseStateChange?: (status: boolean) => void;

  breakpointStore?: BreakpointStore;
}

export class CardHeader extends React.Component<CardHeaderProps> {
  handleCollapse = (_event, status): void => {
    this.props.onCollapseStateChange && this.props.onCollapseStateChange(status);
  };

  get isMobile(): boolean {
    const { isMobileDevice } = this.props.breakpointStore;
    return isMobileDevice;
  }

  @computed
  get menu(): ReactNode {
    const { compact, menu, dropdownMenu } = this.props;

    if (compact || (!menu && !dropdownMenu)) {
      return null;
    }

    if (dropdownMenu) {
      return dropdownMenu;
    }

    const items: MenuProps['items'] = menu;

    return (
      <Dropdown menu={{ items }} trigger={['click']}>
        <Button shape="circle" icon={<MoreOutlined />} className={styles.menuBtn} />
      </Dropdown>
    );
  }

  @computed
  get selectMenu(): ReactNode {
    const { compact, selectMenu } = this.props;

    if (compact || !selectMenu) {
      return null;
    }

    return this.props.selectMenu;
  }

  @computed
  get collapseIcon(): ReactNode {
    if (!this.props.collapsible) {
      return null;
    }

    if (this.props.isCollapsed) {
      return (
        <DownOutlined
          className={styles.collapseButton}
          onClick={(e) => this.handleCollapse(e, false)}
        />
      );
    }

    return (
      <UpOutlined className={styles.collapseButton} onClick={(e) => this.handleCollapse(e, true)} />
    );
  }

  @computed
  get button(): ReactNode {
    const { button } = this.props;

    if (!button) {
      return null;
    }

    return (
      <Button
        type={button.type ?? 'primary'}
        size={button.size ?? undefined}
        className={cx(styles.headerButton, button.className)}
        onClick={button.onClick}
        id={button.id}
        ghost={button.ghost}
      >
        {button.label}
      </Button>
    );
  }

  @computed
  get icon(): ReactNode {
    const { compact, icon, iconClassName } = this.props;

    if (compact || !icon) {
      return null;
    }

    return <div className={cx(styles.icon, iconClassName)}>{icon}</div>;
  }

  @computed
  get image(): ReactNode {
    const { compact, image } = this.props;

    if (compact || !image) {
      return null;
    }

    return <div className={styles.image}>{image}</div>;
  }

  @computed
  get heading(): ReactNode {
    const { compact, heading, headingRight, tag, tagClassName, subHeading, subHeadingNote } =
      this.props;

    return (
      <div className={styles.headings}>
        <div className={styles.heading}>
          <h4 className={styles.title} dangerouslySetInnerHTML={{ __html: heading }} />
          {!compact && tag && <div className={cx(styles.tag, tagClassName)}>{tag}</div>}
          {headingRight && <div className={styles.headingRight}>{headingRight}</div>}
        </div>
        {subHeading && <p className={styles.subHeading}>{subHeading}</p>}
        {subHeadingNote && <p className={styles.subHeading_note}>{subHeadingNote}</p>}
      </div>
    );
  }

  render(): ReactNode {
    const { id, className, standalone, backgroundColor, compact } = this.props;

    return (
      <div
        id={id}
        className={cx(styles.header, className, { [styles.standalone]: standalone })}
        style={{ backgroundColor }}
      >
        <div className={cx(styles.upperContent)}>
          {this.image}
          {this.icon}
          {this.heading}

          {!this.isMobile && this.button && <div className={cx(styles.cta)}>{this.button}</div>}

          {!compact && this.selectMenu}
          {!compact && this.menu}
          {this.collapseIcon}
        </div>

        {this.isMobile && this.button && (
          <div className={cx(styles.lowerContent, styles.cta, styles.onMobile)}>{this.button}</div>
        )}
      </div>
    );
  }
}

export default inject(STORE_BREAKPOINT)(observer(CardHeader));
